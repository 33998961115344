import LAYOUT from '@/layout/layout';
import moduleLayout from '@/views/Settings/Settings.vue';
import scenarioLayout from '../../views/Dialog/Dialog.vue';

export default [
	{
		path: '/settings-page',
		name: 'SettingsPage',
		component: LAYOUT.base,
		children: [
			{
				path: '/',
				component: () => import(/* webpackChunkName: 'settings-engines' */ '@/views/Settings/SettingsPage.vue'),

				children: [
					{
						path: '/',
						name: 'SettingsTab',
						component: () => import(/* webpackChunkName: 'settings-bots' */ '@/views/Settings/tabs/SettingsTab.vue'),
					},
					{
						path: 'bots',
						name: 'SettingsBots',
						component: () => import(/* webpackChunkName: 'settings-bots' */ '@/views/Settings/tabs/BotsTab.vue'),
					},
					{
						path: 'bot-groups',
						name: 'BotGroups',
						component: () => import(/* webpackChunkName: 'settings-bot' */ '@/views/Settings/tabs/BotGroupsTab.vue'),
					},


					{
						path: 'users',
						name: 'users',
						component: () =>
							import(
								/* webpackChunkName: 'op-users' */ '@/views/Settings/pages/Users.vue'
							),
					},
					{
						path: 'roles',
						name: 'SettingsRoles',
						component: () => import(/* webpackChunkName: 'settings-roles' */ '@/views/Settings/tabs/RolesTab.vue'),
					},


					{
						path: 'op-users',
						name: 'op-users',
						component: () =>
							import(
								/* webpackChunkName: 'op-users' */ '@/modules/OperatorPanel/views/settings/UsersTab.vue'
								),
					},
					{
						path: 'subjects',
						name: 'subjects',
						component: () =>
							import(
								/* webpackChunkName: 'op-subjects' */ '@/modules/OperatorPanel/views/settings/SubjectsTab.vue'
								),
					},
					{
						path: 'skill_groups',
						name: 'skillGroups',
						component: () =>
							import(
								/* webpackChunkName: 'op-skill-groups' */ '@/modules/OperatorPanel/views/settings/SkillGroupsTab.vue'
								),
					},
					{
						path: 'statuses',
						name: 'statuses',
						component: () =>
							import(
								/* webpackChunkName: 'op-quick-replies' */ '@/modules/OperatorPanel/views/settings/Statuses.vue'
								),
					},
					{
						path: 'chat_statuses',
						name: 'chatStatuses',
						component: () =>
							import(
								/* webpackChunkName: 'op-quick-replies' */ '@/modules/OperatorPanel/views/settings/ChatStatusesTab.vue'
								),
					},
					{
						path: 'tags',
						name: 'tags',
						component: () =>
							import(
								/* webpackChunkName: 'op-skill-groups' */ '@/modules/OperatorPanel/views/settings/TagsTab.vue'
								),
					},
					{
						path: 'priorities',
						name: 'priorities',
						component: () =>
							import(
								/* webpackChunkName: 'op-skill-groups' */ '@/modules/OperatorPanel/views/settings/PrioritiesTab.vue'
								),
					},
					{
						path: 'op-settings',
						name: 'op-settings',
						component: () =>
							import(
								/* webpackChunkName: 'op-settings' */ '@/modules/OperatorPanel/views/settings/SettingsTab.vue'
								),
					},


					{
						path: 'quick_reply_categories',
						name: 'fastAnswersCategories',
						component: () =>
							import(
								/* webpackChunkName: 'op-quick-reply-categories' */ '@/modules/OperatorPanel/views/settings/QuickReplyCategories.vue'
								),
					},
					{
						path: 'quick_replies',
						name: 'fastAnswers',
						component: () =>
							import(
								/* webpackChunkName: 'op-quick-replies' */ '@/modules/OperatorPanel/views/settings/QuickReplies.vue'
								),
					},

					{
						path: 'instance-settings',
						name: 'InstanceSettings',
						component: () => import(/* webpackChunkName: 'settings-engines' */ '@/views/Settings/tabs/InstanceSettings.vue'),
					},

					{
						path: 'user-fields',
						name: 'UserFields',
						component: () => import(/* webpackChunkName: 'settings-engines' */ '@/views/Settings/tabs/UserFields.vue'),
					},
					{
						path: 'client-fields',
						name: 'ClientFields',
						component: () => import(/* webpackChunkName: 'settings-engines' */ '@/views/Settings/tabs/ClientFields.vue'),
					},
				]
			},

		]
	},
  {
    path: '/settings',
    component: LAYOUT.base,
    redirect: '/settings/bots',
    children: [
      // {
      //   path: 'engines',
      //   component: moduleLayout,
      //   children: [
      //     {
      //       path: '',
      //       name: 'SettingsEngines',
      //       component: () => import(/* webpackChunkName: 'settings-engines' */ '@/views/Settings/tabs/EnginesTab.vue'),
      //     },
      //   ],
      // },
			{
				path: 'instance-settings',
				component: moduleLayout,
				children: [
					{
						path: '',
						name: 'InstanceSettings',
						component: () => import(/* webpackChunkName: 'settings-engines' */ '@/views/Settings/tabs/InstanceSettings.vue'),
					},
				],
			},
      {
        path: 'bots',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'SettingsBots',
            component: () => import(/* webpackChunkName: 'settings-bots' */ '@/views/Settings/tabs/BotsTab.vue'),
          },
        ],
      },
      {
        path: 'bot-groups',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'BotGroups',
            component: () => import(/* webpackChunkName: 'settings-bot' */ '@/views/Settings/tabs/BotGroupsTab.vue'),
          },
        ],
      },
      {
        path: "bot-settings/:id",
        name: "bot_settings",
        component: () => import(/* webpackChunkName: '' */ "@/views/Settings/BotSettings"),
      },
      {
        path: 'hooks',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'SettingsHooks',
            component: () => import(/* webpackChunkName: 'settings-hooks' */ '@/views/Settings/tabs/BotTab_hooks.vue'),
          },
        ],
      },
      {
        path: 'languages',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'SettingsLanguages',
            component: () => import(/* webpackChunkName: 'settings-languages' */ '@/views/Settings/tabs/BotTab_languages.vue'),
          },
        ],
      },
      // {
      //   path: 'users',
      //   component: moduleLayout,
      //   children: [
      //     {
      //       path: '',
      //       name: 'SettingsUsers',
      //       component: () => import(/* webpackChunkName: 'settings-users' */ '@/views/Settings/tabs/UsersTab.vue'),
      //     },
      //   ],
      // },
      {
        path: 'roles',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'SettingsRoles',
            component: () => import(/* webpackChunkName: 'settings-roles' */ '@/views/Settings/tabs/RolesTab.vue'),
          },
        ],
      },
    ],
  },
];
