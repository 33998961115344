<template>
  <div class="table-container">
    <div class="table-wrapper">
      <v-data-table
          :show-select="select_active"
          ref="dataTable"
          :value="selected"
          @input="$emit('update:selected', $event)"

          :headers="active_headers"
          :items="items"
          item-key="id"

          class="elevation-0 table custom-table"
          :class="{'table__without-checkbox': !select_active}"
          checkbox-color="primary"
          :header-props="{ sortIcon: '$vuetify.icons.iconsort' }"
          fixed-header
          disable-pagination
          :hide-default-footer="true"
          :loading="loading"
          :loading-text="loadingItemsText"
          :no-data-text="noDataText"
          :no-results-text="noSearchResultsText"
          :options.sync="options"
          :sort-desc="sortDescending"
          :sort-by="currentSort"
          @update:options="handleOptionsUpdate"

          :search="searchInTable"
          :mobile-breakpoint="mobileBreakpoint"
      >

        <template v-slot:item="{ item, headers, isSelected, select }">
          <tr
            :data-id="item.id"
            :style="getRowStyle(item)"
          >
              <td
                v-for="( header, index ) in headers"
                :key="index"
                :style="getCellStyle(header, item)"
                :class="{
                  'column-sticky': horizontalScrollExist && header.type === 'actions'
                }"
              >
                <template
                  v-if="!header.type && select_active"
                >
                  <v-simple-checkbox
                    :value="isSelected"
                    @input="select($event)"
                    color="primary"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>

                <template
                  v-else-if="header.file_upload"
                >
                  <div class="d-flex justify-center align-center position-relative">

                    <template v-if="item[header.value] && item[header.value].files">
                      <a
                        :href="$sanitizedHrefHttps( file.url )"
                        target="_blank" rel="noopener"
                        v-for="(file, fileIndex) in item[header.value].files"
                        :key="fileIndex"
                        class="mr-1"
                      >
                        <v-img
                          v-if="isImage(file.name)"
                          lazy-src="https://picsum.photos/id/11/10/6"
                          height="17"
                          width="17"
                          :src="file.url"
                        ></v-img>

                        <v-icon v-else size="20" color="black3">mdi-file</v-icon>
                      </a>
                    </template>

                    <v-menu
                      offset-y
                      rounded='sm'
                      :absolute="true"
                      :allow-overflow="true"
                      nudge-bottom="0"
                      max-width="250"
                      min-width="220"
                      content-class="custom-table__file elevation-0"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          elevation="0"
                          height="36"
                          width="36"
                          min-width="auto"
                          v-bind="attrs"
                          v-on="on"
                          text
                          class="custom-table__file--btn px-0 order-1 ml-2"
                        >
                          <v-icon size="20" color="primary">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </template>

                      <v-list dense>
                        <div class="custom-table__file--wrapper">
                          <v-list-item-group>

                            <template v-if="item[header.value] && item[header.value].files">
                              <v-chip
                                v-for="(file, fileIndex) in item[header.value].files"
                                :key="fileIndex"
                                class="custom-table__file--chip"
                                close
                                small
                                color="primary"
                                width="100%"
                                @click:close="deleteFile(file.name, item.id, header.name)"
                              >
                                <v-icon left size="18">
                                  mdi-file-link
                                </v-icon>

                                <a :href="$sanitizedHrefHttps( file.url )" target="_blank" rel="noopener">
                                  {{ file.name }}
                                </a>
                              </v-chip>
                            </template>

                            <v-list-item
                              @click="openFileDialog($event)"
                              class="mt-1"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-paperclip</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                {{i18n.t('modules.customdata.upload')}}
                              </v-list-item-title>
                            </v-list-item>

                            <v-file-input
                              multiple
                              v-model="uploadFiles"
                              accept="image/png, image/jpeg, image/jpg, .pdf, .doc, .docx, .xls, .xlsx"
                              :label="$t('modules.customdata.upload')"
                              v-on:change="uploadFile(item.id, $event, header.name)"
                              class="fileInput"
                              style="display: none;"
                            ></v-file-input>

                          </v-list-item-group>
                        </div>
                      </v-list>
                    </v-menu>
                  </div>
                </template>

                <template
                  v-else-if="header.display_in_table === 'image'"
                >
                  <CdImageItem :imageUrl="item[header.value]" />
                </template>

                <template
                  v-else-if="header.name === 'active'"
                >
                  <v-switch
                    inset
                    color="primary"
                    :input-value="item[header.value]"
                    @change="toggleActiveClient({user_id: item['id'], toggle: $event } )"
                  />
                </template>

                <template
                  v-else-if="header.name === 'channel'"
                >
                  <v-layout align-center justify-center>
                    <v-avatar
                      :key="formatChannelIcons(item).name"
                      size="20"
                    >
                      <img
                        :src="formatChannelIcons(item).icon"
                        :title="formatChannelIcons(item).name"
                        :alt="formatChannelIcons(item).name"
                      />
                    </v-avatar>
                  </v-layout>
                </template>

                <template
                  v-else-if="header.type === 'DATE'"
                >

                  <!-- <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip 
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        @mouseenter="handleHover"
                      >
                        {{ formatDate(item[header.value], true) }}
                      </v-chip>
                    </template>
                    <span>{{ formatDate(item[header.value]) }}</span>
                  </v-tooltip> -->

                  <DynamicTableCell
                    :value="item[header.value]"
                    :itemId="item.id"
                    :itemName="header.value"
                    :itemType="header.type"
                    :table_is_editable="table_is_editable"
                  />

                </template>

                <template
                  v-else-if="header.type === 'actions'"
                  :class="{
                    'column-sticky': horizontalScrollExist
                  }"
                >
                  <v-layout align-center justify-center>

                    <v-btn
                      v-if="chat_room_link_in_actions && item.id"
                      icon
                      x-small
                      color="primary"
                      class="mt-1 mr-1"
                      :href="linkToChat(item.id)"
                      target="_blank"
                      plain
                    >
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="edit_modal_btn"
                      icon
                      color="green"
                      class="mt-1"
                      @click="editItem(item.id)"
                      :title="i18n.t('modules.customdata.entity.edit')"
                    ><v-icon small>$iconedit</v-icon></v-btn>
                    <!-- <v-btn
                      icon
                      color="red"
                      class="mt-1"
                      @click="deleteItem(item.id)"
                      :title="i18n.t('modules.customdata.entity.delete')"
                    ><v-icon small>$icondelete</v-icon></v-btn> -->


                    <CustomDataMacroRun
                      v-if="macros_params?.bot_id && macros_params?.single"
                      :macros_params="macros_params"
                      :item_obj="item"
                    />
                  </v-layout>
                </template>

                <template
                  v-else
                >
                  <DynamicTableCell
                    :value="item[header.value]"
                    :itemId="item.id"
                    :itemName="header.value"
                    :itemType="header.type"
                    :itemEditable="header.non_editable"
                    :itemDisplayType="header.display_type"
                    :itemClickAction="header.click_action"
                    :itemAssociation="header.column_from_association"
                    :headerData="header"
                    :highlight_color="highlightColorByRules(header, item)"
                    :item_obj="item"
                    :entity_field="entity_fields[header.value]"
                    :entity_fields="entity_fields"
                    @open-edit="editItem"
                    :table_is_editable="table_is_editable"
                  />
                </template>
              </td>
            </tr>
        </template>

        <template v-slot:body.append="{headers}">
          <tr
            v-if="propTotalRow"
            class="total-row"
          >
            <td 
              v-for="(header, index) in headers" 
              :key="index"
              class="total-col"
              :class="{'total-col--hidden': header.value == 'data-table-select' || header.value == 'id' || header.value == 'actions'}"
            >
              <template
                v-if="header.total_sum && header.value !== 'data-table-select' && header.value !== 'id' && header.value !== 'actions'"
              >
                {{getColumnSumByHeader(header)}}
              </template>
            </td>
          </tr>
        </template>

      </v-data-table>
    </div>

    <div
        class="table-pagination"
        v-if="selected.length < 1"
    >
      <div class="text-caption mr-5">
        <span class="table-pagination__mobile-hide">{{ $t('modules.customdata.table.pagination.showed') }}</span> {{ itemsLength }} <span class="table-pagination__mobile-hide">{{ $t('modules.customdata.table.pagination.items') }}</span>
      </div>

      <v-spacer></v-spacer>

      {{ /* Select Per Page */ }}
      <div class="d-flex align-center">
        <!-- <div class="text-body-2 mr-2">{{ $t('modules.customdata.table.pagination.rows_per_page') }}:</div> -->
        <v-select
            item-text="name"
            item-value="value"
            v-model="selectDefaultPerPage"
            :items="itemsPerPage"
            @change="resetPagination"
            dense
            outlined
            height="32"
            hide-details
            class="select-per-page"
        ></v-select>
      </div>
      {{ /* end Select Per Page */ }}

      <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="(pageCount >= 3) ? '5' : '0'"
          @input="handlePageChange"
          circle
      ></v-pagination>
    </div>

    <div
      class="table-pagination"
      v-else
    >
      <div class="text-caption">
        {{ $t('modules.customdata.table.pagination.selected') }}: {{ selected.length }} {{ $t('modules.customdata.table.pagination.items') }}
      </div>
      <v-spacer />
    </div>

  </div>
</template>

<script>
import i18n from "@/lang/i18n"
import { mapGetters } from "vuex";
import Vue from "vue";
import axios from "axios";
import _ from 'lodash'

import DynamicTableCell from "@/modules/CustomData/components/DynamicTableCell.vue";
import CdImageItem from "@/modules/CustomData/views/customdata/NewComponents/CdImageItem.vue";
import CustomDataMacroRun from "@/modules/CustomData/components/MacroRun.vue"


const telegramIcon = require('@/assets/logo-telegram.svg');
const viberIcon = require('@/assets/logo-viber.svg');
const messengerIcon = require('@/assets/logo-messenger.svg');
const whatsappIcon = require('@/assets/logo-whatsapp.svg');
const widgetIcon = require('@/assets/logo-kwizbot.svg');

export default {
  name: "DynamicTable",

  components: {
    CdImageItem,
    DynamicTableCell,
    CustomDataMacroRun,
  },

  props: {
    columns_data: {
      type: Array,
      default: () => [],
    },
    select_active: {
      type:Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    // selectedHeaders: {
    //   type: Array,
    //   default: () => [],
    // },
    loadingItemsText: {
      type: String,
      default: i18n.t("modules.customdata.table.loading_items"),
    },
    noDataText: {
      type: String,
      default: i18n.t("modules.customdata.table.no_data_text"),
    },
    noSearchResultsText: {
      type: String,
      default: i18n.t("modules.customdata.table.no_search_results"),
    },
    itemsLength: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },

    defaultPerPage: {
      type: Number,
      default: 5,
    },
    itemsPerPage: {
      type: Array,
      default: () => [],
    },

    pageCount: {
      type: Number,
      default: 0,
    },

    defaultSort: {
      type: String || Array,
      default: () => '' || []
    },

    searchInTable: {
      type: String,
      default: '0',
    },

    propTotalRow: {
      type:Boolean,
      default: false
    },

    mobileBreakpoint: {
      type: Number,
      default: 0,
    },

    multiple_entity: {
      type: String,
      require: true
    },

    entity_fields: {
      type: Object,
      default: () => {},
    },

    ui_table_stylization: {
      type: Object,
      default: () => {},
    },

    macros_params: {
      type: Object,
      default: () => {},
    },

    chat_room_link_in_actions: {
      type: Boolean,
      default: false
    },

    edit_modal_btn: {
      type: Boolean,
      default: true
    },

    table_is_editable: {
      type: Boolean,
      default: true
    },
  },

  data: () => ({
    // selectedRows: [],
    page: 1,
    itemsCount: 0,
    options:{},
    i18n,
    currentSort: '',
    sortDescending: true,
    uploadFiles: [],
    horizontalScrollExist: false,

    handleMouseMove: null,
  }),
  mounted() {
    this.checkForHorizontalScroll();

    this.handleMouseMove = (e) => {
      let ele = document.querySelector('.table-wrapper');
      if (!ele) return;

      let rect = ele.getBoundingClientRect();
      let isMouseOver = e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom;

      if (isMouseOver) {
        let distanceWidth = rect.right - e.clientX;
        let distanceHeight = rect.bottom - e.clientY;

        distanceWidth < 20 && distanceWidth > 0 ? ele.classList.add('more-width') : ele.classList.remove('more-width');
        distanceHeight < 20 && distanceHeight > 0 ? ele.classList.add('more-height') : ele.classList.remove('more-height');
      } else {
        ele.classList.remove('more-width');
        ele.classList.remove('more-height');
      }
    };

    document.addEventListener("mousemove", this.handleMouseMove);
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.handleMouseMove);
  },

  updated() {
    this.checkForHorizontalScroll();
  },
  watch: {
    options: {
      handler (val, valBefore) {
        if (valBefore?.page)
          this.$emit("sortOptions", this.options);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'custom_data_columns_data',
      'custom_data_default_columns_data',
      'engine_settings',
      'bot_settings'
    ]),
    // showHeaders() {
    //   return (this.selectedHeaders)
    //     ? this.selectedHeaders.filter((s) => s.active === true)
    //     : this.headers;
    // },

    // selectVal: {
    //   get() {
    //     return this.selectedRows;
    //   },
    //   set(val) {
    //     this.selectedRows = val;
    //     this.$emit("selectedData", val);
    //   },
    // },

    /**
     * @name selectDefaultPerPage
     * @description v-model for select Per Page Items
     */
    selectDefaultPerPage: {
      get() {
        return this.defaultPerPage;
      },
      set(value) {
        this.$emit("change-default-per-page", value);
      },
    },

    active_headers() {
      let data = []
      if(this.columns_data.length) {
        data = this.columns_data
      } else {
        data = this.custom_data_columns_data
      }

      //TODO: need  tooptimize
      let arr = data.filter((c) => c.active === true)
      return _.sortBy(arr, 'position')
    },

    defaultSortOptions() {
      if (Array.isArray(this.defaultSort)) {
        return {
          sortBy: this.defaultSort[0],
          descending: this.sortDescending,
        };
      } else {
        return {
          sortBy: this.defaultSort,
          descending: this.sortDescending,
        };
      }
    },
  },

  methods: {
    /**
     * @description reset Pager on @change perPage v-select
     */
    resetPagination() {
      this.page = 1;
    },

    /**
     * @name handlePageChange
     * @param value
     * @description call on input/change for pagination and send to parent
     */
    handlePageChange(value) {
      this.$emit("change-page", value);
    },

    editItem(value) {
      this.$emit('edit-item', value)
    },
    deleteItem(value) {
      this.$emit('delete-item', value)
    },

    toggleActiveClient(value) {
      this.$emit('toggle_active-client', value)
    },

    formatChannelIcons(user) {
      switch (user.channel || user.kw_channel) {
        case 'telegram':
          return {
            icon: telegramIcon,
            name: 'Telegram',
          };

        case 'viber':
          return {
            icon: viberIcon,
            name: 'Viber',
          };

        case 'facebook':
          return {
            icon: messengerIcon,
            name: 'Facebook',
          };

        case 'whatsapp':
          return {
            icon: whatsappIcon,
            name: 'Whatsapp',
          };

        case 'widget':
          return {
            icon: widgetIcon,
            name: 'Widget',
          };
        default:
          return {
            icon: widgetIcon,
            name: 'Widget',
          };
      }
    },

    getColumnSumByHeader(header) {
      const sum = this.custom_data_default_columns_data.find((c) => c.value === header.value)?.sum;
      const multiplier = Math.pow(10, 2);
      return Math.round(sum * multiplier) / multiplier || '';
    },

    handleOptionsUpdate(newOptions) {
      if (!newOptions.sortBy?.length || !newOptions.sortDesc?.length) {
        this.currentSort = [this.defaultSortOptions.sortBy];
        this.sortDescending = [this.defaultSortOptions.descending];
      }
    },

    openFileDialog(event) {
      event.target.closest('.v-list-item-group').querySelector('.fileInput label').click();
    },

    /**
     * Upload file
     * @method uploadFile
     *
     * */
    async uploadFile(id, data, field_name) {

      if (!data) return false;

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/update_file/${id}`;
      const request = new FormData();

      let i = 0;
      for (let file of data) {
        request.set('files[' + i + ']', file, file.name);
        i++;
      }

      request.append('field_name', field_name);

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios.post(url, request, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "X-CSRF-TOKEN": token
        },
        params: {
          bot_id: bot_id,
        },
      })
        .then( async (response) => {
            this.$store.dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.uploaded') ]);

            if ( response?.data?.status === 'success' ) {
              this.$emit('update-list');
              this.uploadFiles = [];
              success = true;
            }
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
          }
        );

      return success;
    },

    /**
     * Delete file
     * @method deleteFile
     *
     * */
    async deleteFile(file_name, id, field_name) {

      if (!file_name) return false;

      let success = false;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      const url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/delete_file/${id}`;

      const request = {
        field_name: field_name,
        file_name: file_name
      };

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios.post(url, request, {
        headers: {
          'Content-Type': 'application/json',
          "X-CSRF-TOKEN": token
        },
        params: {
          bot_id: bot_id,
        },
      })
        .then( async (response) => {
            this.$store.dispatch('updateAjaxDialog', [ true, false, i18n.t('modules.customdata.success'), i18n.t('modules.customdata.deleted') ]);

            if ( response?.data?.status === 'success' ) {
              this.$emit('update-list');
              success = true;
            }
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [ err,  i18n.t("common.error"),  i18n.t("common.error_get") ]);
          }
        );

      return success;
    },

    isImage(file) {
      const fileName = file.toLowerCase();
      return (fileName.endsWith('.jpg') ||
        fileName.endsWith('.jpeg') ||
        fileName.endsWith('.png') ||
        fileName.endsWith('.gif') ||
        fileName.endsWith('.bmp') ||
        fileName.endsWith('.svg'));
    },

    checkForHorizontalScroll() {
      // Access the v-data-table element
      const dataTable = this.$refs.dataTable.$el.querySelector('.v-data-table__wrapper');
      if (!dataTable) return; // Guard clause in case the querySelector didn't find the element

      // Compare scrollWidth and clientWidth
      this.horizontalScrollExist = dataTable.scrollWidth > dataTable.clientWidth;
    },

    highlightColorByRules(header, item) {
      let highlight = null

      if(header?.highlight_rules?.rules?.length) {
        const rules = header.highlight_rules.rules
        let i;
        for (i = 0; i < rules.length; i++) {
          const first = rules[i]['0']
          const second = rules[i]['1']
          const action = rules[i].action
          const color = rules[i].color

          if( item?.[first] && item?.[second] ) {
            const compare = this.$getComparator(action);
            if(compare( item?.[first], item?.[second] )) {
              highlight =  color
            }
          }
        }
      }

      return highlight
    },

    getRowStyle(item) {
      let styles = {}
      if(this.ui_table_stylization?.colorize) {
        const colorize = this.ui_table_stylization.colorize

        //set row bgColor
        if(
          colorize?.what === 'row'
          && item?.[colorize?.param_name]
        ) {
          let backgroundColor = item[colorize.param_name]

          if(colorize?.opacity) {
            backgroundColor = this.hexToRgba(backgroundColor, colorize.opacity)
          }
          styles = {...styles, backgroundColor: backgroundColor}
        }
      }

      return styles
    },
    getCellStyle(header, item) {
      let styles = {}
      if(this.ui_table_stylization?.colorize) {
        const colorize = this.ui_table_stylization.colorize

        //set cell bgColor
        if(
          colorize?.what === 'cell'
          && item?.[colorize?.param_name]
          && colorize?.field === header?.name
        ) {
          let backgroundColor = item[colorize.param_name]

          if(colorize?.opacity) {
            backgroundColor = this.hexToRgba(backgroundColor, colorize.opacity)
          }
          styles = {...styles, backgroundColor: backgroundColor}
        }
      }

      return styles
    },
    hexToRgba(hex, opacity) {
      // Remove the leading '#' if present
      hex = hex.replace('#', '');

      // Parse the hex color
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      // Return the RGBA color
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },

    linkToChat(id) {
      return window.location.origin + '/operator_panel/chats?chat_room_id=' + id
    }
  },

  created() {
    this.currentSort = this.defaultSortOptions.sortBy;
    this.sortDescending = this.defaultSortOptions.descending;
  },
};
</script>

<style lang="scss" scoped>
.table-container::v-deep {
  display: flex;
  flex-direction: column;
  flex: auto;
}

/* Pagination */
.table-pagination::v-deep {
  // border-top: 1px solid var(--v-gray-base);
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .select-per-page{
    max-width: 70px;
    &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
      padding: 0 8px 0 12px;
      min-height: 32px;
    }
    &.v-select.v-input--dense .v-select__selection--comma{
      margin: 0;
      font-size: 14px;
      overflow: visible;
    }
    .v-input__append-inner{
      margin-top: 4px !important;
    }

    .v-select__selections {
      flex-wrap: nowrap;
    }
  }

  .v-pagination__item{
    box-shadow: none;
    background: none;
    min-width: 28px;
    max-height: 28px;
    border-radius: 2px;
    border: 1px solid transparent;
    background-color: transparent !important;

    &.v-pagination__item--active {
      border-color: var(--v-primary-base);

      color: var(--v-primary-base);
    }
  }

  .v-pagination {
    .v-pagination__navigation {
      background: transparent;
      box-shadow: none;
    }
  }
}
/* end Pagination */

.table-wrapper {
  height: 300px;
  flex: auto;

  &.more-width {
    ::-webkit-scrollbar {
      width: 8px;
    }

    tr td.column-sticky {
      right: -5px !important;
    }
  }

  &.more-height {
    ::-webkit-scrollbar {
      height: 8px;
    }
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  ::-webkit-scrollbar-track {
    background: var(--v-gray-base);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
    border-radius: 2px;
  }
}
.table::v-deep {
  flex: auto;
  height: 100%;

  table {
    border-top: none;

    @media screen and (min-width: 1300px) {
      zoom: 0.9;
    }
  }

  .v-input--selection-controls__input {
    margin-right: 8px;
  }

  .v-data-table__wrapper {
    height: 100%;
  }
  th{
    border-top: 1px solid var(--v-blueHaze-base);

    /* Sort icon */
    &:not(:first-child) {
      padding-right: 27px !important;
      & > span.v-icon {
        position: absolute;
        right: 12px;
        top: 18px;

        width: 10px !important;
        height: 12px !important;

        svg {
          width: 10px !important;
          height: 12px !important;
        }
      }
    }
  }
  th, td{
    /* style for checkbox selected*/
    &:nth-child(1) {
      padding-left: 10px;
      padding-right: 2px;
    }
    &:nth-child(2) {
      padding-left: 10px;
      border-left: none;
    }
  }

  &.table__without-checkbox {
    th{
      &:first-child {
        padding-right: 27px !important;
        & > span.v-icon {
          position: absolute;
          right: 12px;
          top: 18px;

          width: 10px !important;
          height: 12px !important;

          svg {
            width: 10px !important;
            height: 12px !important;
          }
        }
      }
    }
  }
}
/* Custom table cols */
.table-col-status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-table::v-deep {
  border-radius: 0;
  background: none;

  &.theme--light {
    table {
      background: white;
    }
  }

  table {
    border: 1px solid var(--v-blueHaze-base);
    border-top: none;
  }
  tr {
    th {
      // background-color: var(--v-gray2-base) !important;
      // white-space: nowrap;
      font-weight: 700;

    }
    td{
      &.column-sticky{
        background-color: #fff;
        position: sticky;
        right: 0;
        z-index: 1;

        &:before {
          content: "";
          width: 1px;
          height: 100%;
          background-color: #d0d4e4;
          position: absolute;
          left: -1px;
          top: 0;
        }

        .v-btn--icon.v-size--default {
          //width: auto;
        }
      }
    }
    td *:not(i, button) {
      font-size: 14px !important;
    }
    th, td {
      font-size: 14px !important;
      color: var(--v-black-base) !important;
      padding: 0 10px !important;

      &:not(:last-child) {
        border-right: 1px solid var(--v-blueHaze-base);
      }
    }
    &:first-child {

    }
    &:last-child {
      td {
        //border-bottom: 1px solid var(--v-gray-base);
      }
    }
    &:hover {
      background: var(--v-athensGray-base) !important;
    }
  }

  .v-date-picker-table td {
    padding: 0 !important;
  }

  .v-data-table-header {
    th {
      box-shadow: 0px 0px 25px -8px var(--v-ghost-base), inset 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
    }
  }

  .total-row {
    box-shadow: 0px 2px 0px var(--v-athensGray-base), 2px 0px  0px var(--v-athensGray-base), -2px 0px 0px var(--v-athensGray-base);
    background: var(--v-athensGray-base) !important;

    td {
      border-bottom: 1px solid var(--v-blueHaze-base);

      &.total-col--hidden {
          border-bottom: none;
        }

      &:last-child:not(.total-col--hidden) {
        border-right: 1px solid var(--v-blueHaze-base);
      }
    }

    .total-col--hidden + .total-col--hidden {
      box-shadow: -2px 0px 0px var(--v-athensGray-base);
    }
  }

  .total-col {
    padding: 0 23px !important;
  }
}

.dynamic-table::v-deep {
  position: relative;

  table {
    border-radius: 10px;

    th {
      &:first-child {
        border-top-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
      }
    }
  }

  .table-wrapper {
    border-radius: 10px;
    overflow: hidden;
  }

  .table-pagination {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(calc(-100% - 10px));
    padding-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .table-pagination__mobile-hide {
    display: none;
  }
  .table-pagination .v-pagination__item {
    min-width: 20px;
  }
  .table-pagination::v-deep {
    .v-pagination__more {
      width: 20px;
    }
    .v-pagination__item {
      min-width: 20px;
      padding: 0 2px;
    }
    .v-pagination__navigation {
      width: 20px;
    }
  }
}
</style>

<style lang="scss">
.custom-table__file {
  border: 1px solid var(--v-gray-base);

  .custom-table__file--chip {
    max-width: calc(100% - 16px);
    margin-left: 8px;
    margin-bottom: 4px;

    .v-chip__content {
      justify-content: flex-end;

      a {
        overflow: hidden;
        display: inline-flex;
        justify-content: flex-end;
        color: white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

  }

  .custom-table__file--btn {

    .v-ripple__container {
      display: none;
    }

    .v-icon {
      opacity: 0.7;
    }

    &:hover,
    &:focus
    {
      .v-icon {
        opacity: 1;
      }
    }
  }
}
</style>
