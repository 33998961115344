/**
 * Created by Bezsmertnyi Ievgen on 12.09.2020.
 */

const getTranslateFunc = function () {
  let i18n;

  try {
    i18n = require('@/lang/i18n.js').default;
  } catch (error) {
    // console.log(error);
  }

  return i18n
    ? (translateAlias) => i18n.t(translateAlias)
    : (translateAlias) => translateAlias;
};

const getTranslate = getTranslateFunc();

//Должен называться так же как и в modules_components_obj
let module_name = 'OperatorPanel';

let module_settings = {
  module_name,
};

//Корневой урл модуля
let module_alias = 'operator_panel';

//Полный список разрешений модуля
module_settings.permits = {
  front: {
    //Отображение пунктов меню
    op_show_menu_item: {
      description: 'Отображать в меню пункт операторской панели',
    },
    op_show_setting_menu_item: {
      description: 'Отображать в меню пункт настроек операторской панели',
    },
    //Отображение табов
    op_show_tab_item: {
      description: 'Отображать в таб операторской панели',
    },
  },

  back: {
    //Полный доступ к чтению и записи в апи
    op_full_read_api_access: {
      description: 'Разрешение на чтение всех апи методов',
    },
    op_full_write_api_access: {
      description: 'Разрешение на запись всех апи методов',
    },

    //Права на чтение, запись в окне оп
    op_operator_read_api: {
      description:
        'Разрешение на чтение апи методов связанных с окном оператора',
    },
    op_operator_write_api: {
      description:
        'Разрешение на запись апи методов связанных с окном оператора',
    },

    //Права на чтение, запись сущностей
    op_read_entities_api: {
      description: 'Разрешение на чтение апи методов связанных с сущностями',
    },
    op_write_entities_api: {
      description: 'Разрешение на запись апи методов связанных с сущностями',
    },
    op_read_users_entities_api: {
      description:
        'Разрешение на чтение апи методов связанных с сущностью пользователя',
    },
    op_write_users_entities_api: {
      description:
        'Разрешение на запись апи методов связанных с сущностью пользователя',
    },
    op_write_users_data_entities_api: {
      description: 'Разрешение на изменение данных пользователя',
    },
    op_read_skill_groups_entities_api: {
      description:
        'Разрешение на чтение апи методов связанных с сущностью скилл группы',
    },
    op_write_skill_groups_entities_api: {
      description:
        'Разрешение на запись апи методов связанных с сущностью скилл группы',
    },
    op_read_subjects_entities_api: {
      description:
        'Разрешение на чтение апи методов связанных с сущностью тематики',
    },
    op_write_subjects_entities_api: {
      description:
        'Разрешение на запись апи методов связанных с сущностью тематики',
    },
    op_read_settings_entities_api: {
      description:
        'Разрешение на чтение апи методов связанных с сущностью настроек',
    },
    op_write_settings_entities_api: {
      description:
        'Разрешение на запись апи методов связанных с сущностью настроек',
    },
  },
};

//Пункты в меню
module_settings.menu_items = [
  {
    title: getTranslate('modules.op.module_settings.operator_panel'),
    module: module_name,
    alias: module_alias,
    icon: '$iconmenuop',
    permits: {
      show: 'op_show_menu_item',
    },
    show: true,
    route: 'operator_panel',
  },
  {
    title: getTranslate('modules.op.module_settings.operator_panel_settings'),
    module: module_name + '_settings',
    alias: 'operator_settings',
    icon: '$iconmenuopsettings',
    permits: {
      show: 'op_show_setting_menu_item',
    },
    show: true,
    route: 'operator_settings',
  },
];

//Табы модуля
module_settings.tabs_items = {};

module_settings.tabs_items[module_name] = {
  use_tabs: true,
  tabs_items: [
    {
      tab: getTranslate('modules.op.module_settings.operator_panel'),
      content: '',
      show: true,
      type: 'chats',
      alias: '/chats',
      permits: { show: 'op_show_tab_item' },
      route: 'chats',
    },
    {
      tab: getTranslate('modules.op.module_settings.operator_panel_search'),
      content: '',
      show: true,
      type: 'chats-search',
      alias: '/chats-search',
      permits: { show: 'op_show_settings_users_tab' },
      route: 'chats-search',
    },
		{
			tab: getTranslate('modules.op.module_settings.table_view'),
			content: '',
			show: true,
			type: 'table-view',
			alias: '/table-view',
			permits: { show: 'op_show_settings_users_tab' },
			route: 'table-view',
		},
		{
			tab: getTranslate('modules.op.module_settings.operator_panel_dashboard'),
			content: '',
			show: true,
			type: 'dashboard',
			alias: '/dashboard',
			permits: { show: 'op_show_settings_users_tab' },
			route: 'dashboard',
		},
		{
			tab: getTranslate('modules.op.module_settings.realtime_board'),
			content: '',
			show: true,
			type: 'statistic_real_time',
			alias: '/statistic_real_time',
			permits: { show: 'op_show_settings_users_tab' },
			route: 'statistic_real_time',
		},
  ],
};

//настройки табов - включая алиас для урла и разрешения на модуль в целом
module_settings.tabs_items[module_name + '_settings'] = {
  use_tabs: true,
  tabs_items: [
    {
      tab: getTranslate('modules.op.module_settings.users'),
      content: '',
      show: true,
      type: 'users',
      alias: '/users',
      permits: {
        show: 'op_show_settings_users_tab',
      },
      route: 'users',
    },
    {
      tab: getTranslate('modules.op.module_settings.subjects'),
      content: '',
      show: true,
      type: 'subjects',
      alias: '/subjects',
      permits: {
        show: 'op_show_settings_subjects_tab',
      },
      route: 'subjects',
    },
    {
      tab: getTranslate('modules.op.module_settings.skill_groups'),
      content: '',
      show: true,
      type: 'skill_groups',
      alias: '/skill_groups',
      permits: {
        show: 'op_show_settings_skill_groups_tab',
      },
      route: 'skill_groups',
    },
    {
      tab: getTranslate('modules.op.module_settings.quick_reply_categories'),
      content: '',
      show: true,
      type: 'quick_reply_categories',
      alias: '/quick_reply_categories',
      permits: {
        show: 'op_show_settings_quick_reply_categories_tab',
      },
      route: 'quick_reply_categories',
    },
    {
      tab: getTranslate('modules.op.module_settings.quick_replies'),
      content: '',
      show: true,
      type: 'quick_replies',
      alias: '/quick_replies',
      permits: {
        show: 'op_show_settings_quick_replies_tab',
      },
      route: 'quick_replies',
    },
    {
      tab: getTranslate('modules.op.module_settings.statuses'),
      content: '',
      show: true,
      type: 'statuses',
      alias: '/statuses',
      permits: {
        show: 'op_show_settings_statuses_tab',
      },
      route: 'statuses',
    },
    {
      tab: getTranslate('modules.op.module_settings.chatStatuses'),
      content: '',
      show: true,
      type: 'chatStatuses',
      alias: '/chatStatuses',
      permits: {
        show: 'op_show_settings_chat_statuses_tab',
      },
      route: 'chat_statuses',
    },
		{
			tab: getTranslate('modules.op.module_settings.tags'),
			content: '',
			show: true,
			type: 'tags',
			alias: '/tags',
			permits: {
				show: 'op_show_settings_tags_tab',
			},
			route: 'tags',
		},
		{
			tab: getTranslate('modules.op.module_settings.priorities'),
			content: '',
			show: true,
			type: 'priorities',
			alias: '/priorities',
			permits: {
				show: 'op_show_settings_priorities_tab',
			},
			route: 'priorities',
		},
    {
      tab: getTranslate('modules.op.module_settings.settings'),
      content: '',
      show: true,
      type: 'settings',
      alias: '/settings',
      permits: {
        show: 'op_show_settings_settings_tab',
      },
      route: 'settings',
    },
  ],
};

//Подключенные компоненты
module_settings.components = [
  {
    name: module_name,
    path: 'Component/Chats.vue',
  },
  {
    name: module_name + '_settings',
    path: 'Component/Settings.vue',
  },
];

//Экспорт для vue и для node
module.exports = { module_settings };
module.exports.default = { module_settings };
